import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./pages/home";
import { AppShell, Container } from "@mantine/core";
import { AppFooter } from "./components/footer";
import { Header } from "./components/header";
import DocumentPage from "./pages/DocumentPage";
import { RemotePrams } from "./services/fb-remote";
import { useHeadroom } from "@mantine/hooks";

function App() {
  const pinned = useHeadroom({ fixedAt: 8 });
  return (
    <BrowserRouter>
      <AppShell padding="md" header={{ height: 100, collapsed: !pinned }} footer={{ height: 130 }}>
        <AppShell.Header style={{ backgroundImage: "linear-gradient(to right, #2BD5C4, #C5E86C)" }}>
          <Container>
            <Header />
          </Container>
        </AppShell.Header>

        <AppShell.Main>
          <Container>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route
                path="/privacy"
                element={<DocumentPage remoteData={RemotePrams.PRIVACY_POLICY} title="Privacy" />}
              />
              <Route path="*" element={<Home />} />
            </Routes>
          </Container>
        </AppShell.Main>

        <AppShell.Footer>
          <Container>
            <AppFooter />
          </Container>
        </AppShell.Footer>
      </AppShell>
    </BrowserRouter>
  );
}

export default App;
