import { Group, Anchor, Flex, Text, ActionIcon } from "@mantine/core";
import { ReactComponent as SMWLogo } from "../assets/logo.svg";
import { IconBrandFacebookFilled, IconBrandGoogleMaps, IconBrandWaze, IconBrandX } from "@tabler/icons-react";
import { useNavigate } from "react-router-dom";

const links = [{ link: "/privacy", label: "Privacy" }];
const socials = [
  { link: "https://facebook.com/Sussexmarine", label: "Facebook", icon: <IconBrandFacebookFilled /> },
  { link: "https://x.com/Sussexmarineltd", label: "X", icon: <IconBrandX /> },
  {
    link: "https://www.google.com/maps/place/Sussex+Marine+Water+Sports+-+Seadoo+(Main+Dealer)+PWC+Jet+Ski+Sales/@50.8313671,-0.2453285,17.01z/data=!4m6!3m5!1s0x48759a6edc3a696d:0x9e53615a3d39160d!8m2!3d50.8309533!4d-0.2440988!16s%2Fg%2F1tqnl2ds?entry=ttu",
    label: "Google Maps",
    icon: <IconBrandGoogleMaps />,
  },
  { link: "https://www.waze.com/ul?ll=50.83087,-0.24447", label: "Waze", icon: <IconBrandWaze /> },
];

export function AppFooter() {
  const navigate = useNavigate();

  const items = links.map((link) => (
    <Anchor c="dimmed" key={link.label} onClick={() => navigate(link.link)} size="sm">
      {link.label}
    </Anchor>
  ));
  const socialLinks = socials.map((link) => (
    <ActionIcon key={link.label} variant="subtle" color="gray" size="lg" radius="md" title={link.label} onClick={() => window.open(link.link)}>
      {link.icon}
    </ActionIcon>
  ));

  return (
    <Flex align="center" justify="space-between" py="lg">
      <Group>
        <SMWLogo height={56} width={56} color="primary" />
        <Text visibleFrom="sm" size="sm">
          <b>Sussex Marine Watersports Ltd</b>
          <br />
          BN42 4DP, Brighton, United Kingdom
          <br />
          <a href="tel://0441273596660">01273 596660</a>
          <br />
          <a href="mailto://info@jetbikes.com">info@jetbikes.com</a>
        </Text>
      </Group>
      <Group align="center" justify="flex-end" gap={8}>
        {items}
        {socialLinks}
      </Group>
    </Flex>
  );
}
