/* eslint-disable @typescript-eslint/no-explicit-any */
import { getBoolean, getNumber, getString } from "firebase/remote-config";
import { remoteConfig } from "./firebase-config";

export enum RemotePrams {
  PRIVACY_POLICY = "document_privacy",
  apiKeyGoogle = "google_api_key",
  apiKeyW3W = "w3w_api_key",
  modelYears = "Home_Timeline"
}

export function getRemoteString(key: RemotePrams): string {
  return getString(remoteConfig, key);
}
export function getRemoteNumber(key: RemotePrams): number {
  return getNumber(remoteConfig, key);
}

export function getRemoteBoolean(key: RemotePrams): boolean {
  return getBoolean(remoteConfig, key);
}

export function getRemoteJSON(key: RemotePrams): any {
  return JSON.parse(getString(remoteConfig, key));
}
