import { UnstyledButton, useComputedColorScheme } from "@mantine/core";
import { ReactComponent as BadgeBlack } from "../assets/badges/badge_apple_black.svg";
import { ReactComponent as BadgeWhite } from "../assets/badges/badge_apple_white.svg";
import { useTranslation } from "react-i18next";

export enum BadgeAppleTheme {
  auto,
  black,
  white,
}

export function BadgeApple(prop: {
  width?: string | number | undefined;
  height?: string | number | undefined;
  vsisibleFrom?: string;
  theme?: BadgeAppleTheme;
}) {
  const { t } = useTranslation();
  const colorScheme = useComputedColorScheme();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function getBadge(theme: BadgeAppleTheme): any {
    switch (theme) {
      case BadgeAppleTheme.black:
        return <BadgeBlack width={prop.width ?? 119} height={prop.height ?? 40} />;
      case BadgeAppleTheme.white:
        return <BadgeWhite width={prop.width ?? 119} height={prop.height ?? 40} />;
      default:
        // auto
        return colorScheme === "light" ? getBadge(BadgeAppleTheme.black) : getBadge(BadgeAppleTheme.white);
    }
  }

  return (
    <UnstyledButton
      visibleFrom={prop.vsisibleFrom}
      title={t("alt.badge.apple")}
      onClick={() =>
        window.open("https://apps.apple.com/app/apple-store/id426268497?pt=532327&ct=app.jetbikes.com&mt=8")
      }
    >
      {getBadge(prop.theme ?? BadgeAppleTheme.auto)}
    </UnstyledButton>
  );
}
