import { Group, Flex, Text, UnstyledButton } from "@mantine/core";
import { ReactComponent as SMWLogo } from "../assets/logo.svg";
import { useNavigate } from "react-router-dom";
import { BadgeApple, BadgeAppleTheme } from "./BadgeApple";

export function Header() {
  const navigate = useNavigate();

  return (
    <Flex align="center" justify="space-between" h={100}>
      <UnstyledButton onClick={() => navigate("/")} c="white">
        <Group>
          <SMWLogo height={56} width={56} />
          <Text fz={32} fw={900} style={{ lineHeight: 1 }}>
            SUSSEX MARINE
            <br />
            WATERSPORTS
          </Text>
        </Group>
      </UnstyledButton>
      <BadgeApple vsisibleFrom="md" theme={BadgeAppleTheme.white} />
    </Flex>
  );
}
