import { Image } from "@mantine/core";
import { useTranslation } from "react-i18next";

export function DevicePhone(props: { src: string; vsisibleFrom?: string }) {
  const { t } = useTranslation();
  
  return (
    <Image
      visibleFrom={props.vsisibleFrom}
      src={props.src}
      radius="xl"
      style={{ border: "12px solid black" }}
      w="80%"
      alt={t('alt.app.screenshot')}
      fit="contain"
    />
  );
}
