import { Container, Stack, Text, TypographyStylesProvider } from "@mantine/core";
import { RemotePrams, getRemoteJSON } from "../services/fb-remote";

export interface DocumentProps {
  title: string;
  body?: string;
  section?: { title?: string; points: string[] }[];
  points?: string[];
}

export default function DocumentPage(props: { remoteData: RemotePrams; title: string }) {
  // eslint-disable-next-line react-hooks/exhaustive-deps

  const content: DocumentProps[] = getRemoteJSON(props.remoteData);

  return (
    <Container size="lg" style={{ background: "primary" }}>
      {content.map((s, i) => {
        return (
          <Stack key={i} mt={i === 0 ? "" : "lg"}>
            <Text fz={28} fw={700}>
              {i + 1}. {s.title}
            </Text>
            {s.body && (
              <TypographyStylesProvider>
                <div dangerouslySetInnerHTML={{ __html: s.body }} />
              </TypographyStylesProvider>
            )}
            {s.section?.map((p, ii) => {
              return (
                <Stack gap={4} key={ii}>
                  <Text fw={500} fz={20}>
                    {i + 1}.{ii + 1}. {p.title}
                  </Text>
                  {p.points?.map((pp, iii) => {
                    return (
                      <div key={iii}>
                        <Text fw={500} pl="xs">
                          {i + 1}.{ii + 1}.{iii + 1}.
                        </Text>
                        <TypographyStylesProvider>
                          <div dangerouslySetInnerHTML={{ __html: pp }} />
                        </TypographyStylesProvider>
                      </div>
                    );
                  })}
                </Stack>
              );
            })}
            {s.points?.map((pp, ii) => {
              return (
                <div key={ii}>
                  <Text fw={500} pl="xs">
                    {i + 1}.{ii + 1}.
                  </Text>
                  <TypographyStylesProvider>
                    <div dangerouslySetInnerHTML={{ __html: pp }} />
                  </TypographyStylesProvider>
                </div>
              );
            })}
          </Stack>
        );
      })}
    </Container>
  );
}
