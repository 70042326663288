import { Title, Text, Stack, Grid, Image } from "@mantine/core";
import image from "../assets/shot1.jpeg";
import { DevicePhone } from "../components/DevicePhone";
import { BadgeApple } from "../components/BadgeApple";
import { useTranslation } from "react-i18next";
import AppIcon from "../assets/appicon.jpg";

function Home() {
  const { t } = useTranslation();

  return (
    <Grid grow align="center" justify="space-between" pt="md">
      <Grid.Col span={7}>
        <Stack>
          <Image src={AppIcon} radius="lg" w="20%" fit="contain" alt={t("alt.app.icon")} />
          <Title>{t("app.title")}</Title>
          <Title c="dimmed" fw={300} mt={-16}>
            {t("app.subtitle")}
          </Title>
          <Text py="md">{t("app.description")}</Text>

          <BadgeApple />
        </Stack>
      </Grid.Col>
      <Grid.Col
        visibleFrom="sm"
        span={5}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <DevicePhone src={image} />
      </Grid.Col>
    </Grid>
  );
}

export default Home;
