import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "@mantine/core/styles.css";
import { createTheme, MantineProvider } from "@mantine/core";
import "./i18n";

const theme = createTheme({
  fontFamily: "Roboto, sans-serif",
  colors: {
    grey: [
      "#f5f5f5",
      "#e7e7e7",
      "#cdcdcd",
      "#b2b2b2",
      "#9a9a9a",
      "#8b8b8b",
      "#848484",
      "#717171",
      "#656565",
      "#575757",
    ],
    gradientTeal: [
      "#e2fffc",
      "#d2f8f5",
      "#aaefe8",
      "#7ee5db",
      "#5addd0",
      "#43d8c9",
      "#32d6c6",
      "#1ebeae",
      "#02a99b",
      "#009386",
    ],
    gradientGreen: [
      "#f6fde5",
      "#eef9d2",
      "#ddf2a8",
      "#caea79",
      "#bbe452",
      "#b1e039",
      "#abde2a",
      "#95c41c",
      "#83af12",
      "#6f9700",
    ],
  },
});

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <React.StrictMode>
    <MantineProvider theme={theme} defaultColorScheme="auto">
      <App />
    </MantineProvider>
  </React.StrictMode>
);
